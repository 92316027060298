@layer components {
  /*
		Tabs
		*/

  .tab-list {
    @apply flex -space-x-2 rounded-full bg-zinc-200 p-1;
  }

  .tab-list-sm {
    @apply tab-list;
    @apply -space-x-1 rounded-full p-1;
  }

  .tab {
    @apply flex items-center justify-center whitespace-nowrap rounded-full font-body text-base font-medium ui-selected:bg-white ui-selected:text-zinc-800 hover:text-zinc-800;
    @apply h-component px-6 lg:px-8;
  }

  .tab-list-sm .tab {
    @apply h-component-sm rounded-full px-4 font-body text-sm;
  }

  /*
		Buttons
		*/

  .button {
    @apply text-center;
    @apply inline-flex items-center;
    @apply border-2;
    @apply px-6 py-2 lg:px-6 lg:py-2;
    @apply whitespace-nowrap;
    @apply font-body text-sm;
    @apply rounded-full;
    @apply transition ease-out;
    @apply space-x-2;
  }

  .button-light {
    @apply bg-white text-zinc-800 hover:border-zinc-200 hover:bg-zinc-200;
    @apply border-white focus-within:border-white active:border-white;
  }

  .button-light.button-outline {
    @apply border-white bg-transparent text-white hover:bg-white hover:text-zinc-800;
  }

  .button-dark {
    @apply bg-zinc-950 text-zinc-50 hover:border-zinc-800 hover:bg-zinc-800;
    @apply border-black focus-within:border-black active:border-black;
  }

  .button-dark.button-outline {
    @apply border-zinc-50 bg-transparent text-zinc-50 hover:bg-zinc-50 hover:text-zinc-950;
  }

  .button-full {
    @apply w-full;
  }

  .icon-button {
    @apply flex shrink-0 items-center justify-center rounded-full border;
  }

  .icon-button-sm {
    @apply icon-button;
    @apply h-component w-component;
    @apply border-[1.5px];
  }

  .icon-button-lg {
    @apply icon-button;
    @apply h-component w-component;
    @apply border-[1.5px];
  }

  .icon-button-light {
    @apply icon-button;
    @apply border-white bg-white text-zinc-800 hover:bg-zinc-800 hover:text-white;
  }

  .icon-button-dark {
    @apply icon-button;
    @apply border-zinc-900 bg-zinc-900 text-white hover:border-white hover:bg-white hover:text-zinc-800;
  }

  .button-group {
    @apply h-component flex overflow-hidden rounded-full border bg-white p-0.5;
    @apply group-[.dark]:bg-zinc-700;
  }

  .button-group-button {
    @apply flex w-1/2 items-center justify-center rounded-full font-body text-sm font-medium;
  }

  /* Input fields */

  .field-label {
    @apply font-body text-sm;
  }

  .text-field {
    @apply h-10 w-full rounded-full border px-4 font-body text-sm transition  ease-out disabled:cursor-not-allowed disabled:opacity-50;
    @apply border-zinc-400 bg-white text-zinc-500 focus:border-zinc-300; /* light is default */
  }

  .text-field-light {
    @apply text-field;
  }

  .text-field-dark {
    @apply text-field;
    @apply border-zinc-700 bg-zinc-800 text-zinc-50 focus:border-zinc-600;
  }

  .radio-selection {
    @apply relative mr-[15px] h-5 w-5 cursor-pointer appearance-none rounded-full border-zinc-500 bg-gray-200 checked:before:absolute checked:before:left-1/2 checked:before:top-1/2 checked:before:block checked:before:h-[10px] checked:before:w-[10px] checked:before:-translate-x-1/2 checked:before:-translate-y-1/2 checked:before:rounded-full checked:before:bg-gray-900 checked:before:content-[''];
  }

  .selection-option {
    @apply h-component flex cursor-pointer items-center  bg-white px-3 font-body text-sm text-gray-900 focus:border-zinc-300;
  }

  /* Progress dots */
  .dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #71717a;
  }
  .dot-active {
    background-color: #71717a;
    border: 1px solid white;
  }
  .dot-done {
    background-color: white;
  }

  .objective-tab {
    background-color: #27262b;
    color: white;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
    padding: 15px 20px;
    flex-grow: 1;
    box-sizing: border-box;
  }

  .objective-tab-selected {
    background-color: white;
    color: #27262b;
  }

  /*
		Tooltip
		*/

  .tooltip-content {
    @apply rounded bg-zinc-900 px-3 py-2 text-sm font-normal text-white shadow;
  }

  /*
		Flexible grid
		*/

  .grid-flexible {
    grid-template-columns: repeat(60, 1fr);
  }

  /* 2xl */
  @media (min-width: 1280px) {
    .grid-item:nth-child(20n + 1),
    .grid-item:nth-child(20n + 2),
    .grid-item:nth-child(20n + 3),
    .grid-item:nth-child(20n + 4),
    .grid-item:nth-child(20n + 5) {
      grid-column: span 12;
    }

    .grid-item:nth-child(20n + 6),
    .grid-item:nth-child(20n + 7),
    .grid-item:nth-child(20n + 8) {
      grid-column: span 20;
    }

    .grid-item:nth-child(20n + 9),
    .grid-item:nth-child(20n + 10),
    .grid-item:nth-child(20n + 11),
    .grid-item:nth-child(20n + 12) {
      grid-column: span 15;
    }

    .grid-item:nth-child(20n + 13),
    .grid-item:nth-child(20n + 14) {
      grid-column: span 30;
    }
  }

  /* lg */
  @media (min-width: 1024px) {
    .grid-item:nth-child(20n + 1),
    .grid-item:nth-child(20n + 2),
    .grid-item:nth-child(20n + 3),
    .grid-item:nth-child(20n + 4) {
      grid-column: span 15;
    }

    .grid-item:nth-child(20n + 5),
    .grid-item:nth-child(20n + 6),
    .grid-item:nth-child(20n + 7) {
      grid-column: span 20;
    }

    .grid-item:nth-child(20n + 8),
    .grid-item:nth-child(20n + 9),
    .grid-item:nth-child(20n + 10),
    .grid-item:nth-child(20n + 11) {
      grid-column: span 15;
    }

    .grid-item:nth-child(20n + 12),
    .grid-item:nth-child(20n + 13),
    .grid-item:nth-child(20n + 14) {
      grid-column: span 20;
    }
  }

  /* md */
  @media (min-width: 640px) {
    .grid-item {
      grid-column: span 30;
    }
  }

  /* sm */
  @media (max-width: 639px) {
    .grid-item {
      grid-column: span 60;
    }
  }
}
